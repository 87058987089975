import Row from "react-bootstrap/Row";
import React from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

// assets
import demoScreen from "./assets/img/demo-screen.mp4"
import { ReactComponent as Logo } from '../../assets/poker-ledger-logo.svg'

// styles
import './Landing.css'

export default function Landing() {
    return (
        <>
            <MastHead></MastHead>
        </>
    );
}

const MastHead = () =>
    <header className="masthead">
        <Container className="px-5">
            <Row className="gx-5 align-items-center">
                <Col lg={6}>
                    <div className="mb-5 mb-lg-0 text-center text-lg-start">
                        <Logo/>
                        <p className="lead fw-bold text-muted">Simplify Your Poker Game Management!</p>
                        <p className="text-muted mb-5">Effortlessly track buy-ins, cash-outs, and balances for every game.</p>
                    </div>
                </Col>
                <Col lg={6}>
                    <DeviceMockup></DeviceMockup>
                </Col>
            </Row>
        </Container>
    </header>

const DeviceMockup = () =>
    <div className="device-mockup">
        <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                    <stop className="gradient-start-color" offset="0%"></stop>
                    <stop className="gradient-end-color" offset="100%"></stop>
                </linearGradient>
            </defs>
            <circle cx="50" cy="50" r="50"></circle>
        </svg>
        <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83"
             xmlns="http://www.w3.org/2000/svg">
            <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03"
                  transform="translate(120.42 -49.88) rotate(45)"></rect>
            <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03"
                  transform="translate(-49.88 120.42) rotate(-45)"></rect>
        </svg>
        <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100"
             xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"></circle>
        </svg>
        <div className="device-wrapper">
            <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                <div className="screen bg-black">
                    <video muted="muted" autoPlay={true} loop={true}
                           style={{maxWidth: '100%', height: '100%'}}
                           src={demoScreen}></video>
                </div>
            </div>
        </div>
    </div>
