export const authProvider = {
    isAuthenticated: false,
    async load() {
        const response = await fetch("/auth");
        authProvider.isAuthenticated = response.status === 204
    },
    async register(email, password, firstName, lastName, houseDescription) {
        if (authProvider.isAuthenticated) {
            return
        }
        const response = await fetch("/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password, firstName, lastName, description: houseDescription }),
        });
        const res = await response.json();
        if (response.status === 201 && res.id) {
            console.log("register() response", {res})
            authProvider.isAuthenticated = true
        } if (response.status === 409) {
            throw new Error(`A user already exists with email: ${email}`)
        } else {
            throw new Error(`Unknown server error. status:${response.status}, message:${res.message}`)
        }
    },
    async login(email, password) {
        const response = await fetch("/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        });
        const res = await response.json();
        if (response.status === 200 && res.id) {
            authProvider.isAuthenticated = true
        } else {
            throw new Error(res.message)
        }
    },
    async logout() {
        const response = await fetch("/logout", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const res = await response.json();
        if (response.status === 202) {
            authProvider.isAuthenticated = false
        } else {
            throw new Error(res.message)
        }
    }
}