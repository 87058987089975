import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {InputGroup, ListGroup} from "react-bootstrap";
import {Search, X} from "react-bootstrap-icons";
import React from "react";
import * as PropTypes from "prop-types";

export function PlayerSearch(props) {
    return <Row className="player-search mb-3 mt-3">
        <Col>
            <Form.Group>
                <InputGroup>
                    <InputGroup.Text><Search/></InputGroup.Text>
                    <Form.Control
                        type="text"
                        onChange={props.onChange}
                        placeholder="search for a player..."
                        value={props.value}
                    />
                    {props.value.length > 0 &&
                        <InputGroup.Text onClick={props.onClick}><X/></InputGroup.Text>}
                </InputGroup>
                <ListGroup>
                    {props.showTypeAheadResults > 0 &&
                        props.typeaheadResults.map(props.callbackfn)
                    }
                </ListGroup>
            </Form.Group>
        </Col>
    </Row>;
}

PlayerSearch.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    onClick: PropTypes.func,
    showTypeAheadResults: PropTypes.bool,
    typeaheadResults: PropTypes.arrayOf(PropTypes.any),
    callbackfn: PropTypes.func
};
