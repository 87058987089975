import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDate} from "../../../utilities";
import {IconTooltipButton} from "../IconTooltipButton";
import {PersonFillAdd} from "react-bootstrap-icons";
import React from "react";
import * as PropTypes from "prop-types";

export function GameHeader(props) {
    return <div className="header">
        <Row className="align-items-center">
            <Col xs={5}>
                <h3 className="game-description">{props.game.description}</h3>
            </Col>
            <Col xs="5" className="ms-auto">
                <span className="game-date text-white">{formatDate(props.game.date)}</span>
            </Col>
            <Col xs={2} className="d-flex justify-content-end">
                <IconTooltipButton tooltipMessage={"Add a player"} icon={<PersonFillAdd/>} onClick={props.onClick}/>
            </Col>
        </Row>
    </div>;
}

GameHeader.propTypes = {
    game: PropTypes.any,
    onClick: PropTypes.func
};
