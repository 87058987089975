import useLongPress from "../../../hooks/useLongPress";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatMoney} from "../../../utilities";
import React, {useState} from "react";
import {Badge} from "react-bootstrap";
import {Check, X} from "react-bootstrap-icons";
import {BsCashStack} from "react-icons/bs";
import {TbFlagQuestion} from "react-icons/tb";
import {BiLogoVenmo} from "react-icons/bi";
import {SiCashapp, SiPaypal} from "react-icons/si";
import {updateTransaction} from "../../../services/ledgerService";

export function TransactionDetail({gameId, transaction, netAmount}) {
    let [txn, updateTxn] = useState(transaction)

    let buyIn = '';
    let cashOut = '';
    let net = '';

    switch (transaction.type) {
        case 'BUY_IN':
            buyIn = transaction.amount.toFixed(2)
            cashOut = '';
            net = (netAmount / 100).toFixed(2)
            break;
        case 'CASH_OUT':
            buyIn = '';
            cashOut = transaction.amount.toFixed(2);
            net = (netAmount / 100).toFixed(2)
            break;
        default:
            buyIn = '';
            cashOut = '';
            net = (netAmount / 100).toFixed(2)
    }

    const onLongPress = (e) => {
        console.log("long press triggered!", {target: e.target})
    }

    const onClick = (e) => {
        console.log('click triggered', {target: e.target})
    }

    const longPressEventHandler = useLongPress({onLongPress, onClick})

    const toggleTransactionStatus = async () => {
        console.log("updating transaction status")
        const updatedTxn = {...txn, isSettled: !txn.isSettled};
        console.log("updating transaction status", {txn, updatedTxn})
        try {
            const res = await updateTransaction({gameId, transaction: updatedTxn})
            console.log("api result", {res})
            updateTxn(updatedTxn)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Row className="p-2 detail" {...longPressEventHandler}>
            <Col xs={3} md={4}><TransactionStatus transaction={txn} onStatusToggle={toggleTransactionStatus}/></Col>
            <Col xs={3} md={2} className="money">{formatMoney(buyIn)}</Col>
            <Col xs={3} md={2} className="money">{formatMoney(cashOut)}</Col>
            <Col xs={3} md={2} className="money">{formatMoney(net)}</Col>
        </Row>
    );
}

function TransactionStatus({transaction, onStatusToggle}) {
    let transactionType;
    let paymentType;

    switch (transaction.type) {
        case 'BUY_IN':
            transactionType = 'IN';
            break;
        case 'CASH_OUT':
            transactionType = 'OUT';
            break;
        default:
            transactionType = 'Unknown';
    }

    switch (transaction.paymentType) {
        case 'CASH':
            paymentType = <BsCashStack title={"Cash"} />
            break;
        case 'CASH_APP':
            paymentType = <SiCashapp title={"CashApp"} />
            break;
        case 'PAYPAL':
            paymentType = <SiPaypal title={"PayPal"} />
            break;
        case 'VENMO':
            paymentType = <BiLogoVenmo title={"Venmo"} />
            break;
        default:
            paymentType = <TbFlagQuestion title={"Unknown"} />
    }

    return (
        <div className="transaction-status">
            <Col>{transactionType}</Col>
            <Col><span>{paymentType}</span></Col>
            <Col onClick={onStatusToggle}>
                <Badge bg={transaction.isSettled ? "success" : "warning"}>
                    {transaction.isSettled ? <Check/> : <X/>}
                </Badge>
            </Col>
        </div>
    );
}