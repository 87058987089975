import { useState, useRef, useCallback } from 'react';

export default function useLongPress({ onLongPress = () => { }, onClick = () => {}, delay = 300 } = {}) {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef();
    const target = useRef();

    const start = useCallback((event) => {
        if (event.target) {
            event.target.addEventListener("touched", preventDefault, { passive: false })
            target.current = event.target
        }
        timeout.current = setTimeout(() => {
            onLongPress(event);
            setLongPressTriggered(true);
        }, delay);
    }, [onLongPress, delay]);

    const stop = useCallback((event, shouldTriggerClick = true) => {
        timeout.current && clearTimeout(timeout.current)
        if (shouldTriggerClick && !longPressTriggered) {
            onClick(event);
        }
        setLongPressTriggered(false);
        if (target.current) {
            target.current.removeEventListener("touched", preventDefault);
        }
    }, [onClick, longPressTriggered]);

    return {
        onMouseDown: (e) => start(e),
        onTouchStart: (e) => start(e),
        onMouseUp: (e) => stop(e),
        onMouseLeave: (e) => stop(e, false),
        onTouchEnd: (e) => stop(e),
    };
}

const isTouchEvent = event => {
    return "touches" in event;
};
const preventDefault = event => {
    if (!isTouchEvent(event)) return;
    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};
