export function formatDate(date) {
    return new Date(date).toLocaleDateString('en-us', {
        timeZone: 'UTC',
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    })
}

export function formatMoney (amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(amount)
}