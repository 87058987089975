import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatMoney} from "../../../utilities";
import React from "react";
import * as PropTypes from "prop-types";

export function GameTotals(props) {
    return <>
        <Row className={"game-table-header mt-3"}>
            <Col xs={3} md={4}><h3>Player</h3></Col>
            <Col xs={3} md={2}><h3 className="text-end">Buy-In</h3></Col>
            <Col xs={3} md={2}><h3 className="text-end">Cash-Out</h3></Col>
            <Col xs={3} md={2}><h3 className="text-end">Net</h3></Col>
            <Col xs={12} md="auto"></Col>
        </Row>
        <Row>
            <hr/>
        </Row>
        <Row className={"game-table-header"}>
            <Col xs={3} md={4}><h3>Game Totals</h3></Col>
            <Col xs={3} md={2}><h3 className="money">{formatMoney(props.game.totalBuyIns)}</h3></Col>
            <Col xs={3} md={2}><h3 className="money">{formatMoney(props.game.totalCashOuts)}</h3></Col>
            <Col xs={3} md={2}><h3
                className={`money fw-bold ${props.game.totalNet >= 0 ? "text-success" : "text-danger"}`}>{formatMoney(props.game.totalNet)}</h3>
            </Col>
            <Col xs={12} md="auto"></Col>
        </Row>
    </>;
}

GameTotals.propTypes = {game: PropTypes.any};
