import React, {useState} from 'react';
import {addPlayer, fetchGame, removePlayer} from '../../services/ledgerService'

import './Ledger.css';
import {redirect, useLoaderData, useRouteLoaderData} from "react-router-dom";
import Col from "react-bootstrap/Col";
import {ListGroup} from "react-bootstrap";
import {AddPlayerModal} from "./AddPlayerModal";
import {AddTransactionModal} from "./AddTransactionModal";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {GameHeader} from "./components/GameHeader";
import {GameTotals} from "./components/GameTotals";
import {PlayerSearch} from "./components/PlayerSearch";
import {PlayerStats} from "./components/PlayerStats";


export function Ledger() {
    const { house } = useRouteLoaderData("root")
    const { game } = useLoaderData();
    const [members, updateMembers] = useState(house.members)
    const addMember = (member) => {
        updateMembers([...members, member])
    }

    const [players, updatePlayers] = useState(game.players)
    const addPlayerState = (player) => {
        updatePlayers([...players, player])
    }
    const removePlayerState = (playerId) => {
        const updatedPlayers = players.filter(p => p.id !== playerId)
        updatePlayers(updatedPlayers)
    }

    // add player modal controls
    const [showAddPlayer, setShowAddPlayer] = useState(false)
    const handleCloseAddPlayer  = () => (setShowAddPlayer(false))
    const handleShowAddPlayer  = () => (setShowAddPlayer(true))

    // add transaction modal controls
    const [showAddTransaction, setShowAddTransaction] = useState(false)
    const handleCloseAddTransaction = () => setShowAddTransaction(false)
    const handleShowAddTransaction = () => setShowAddTransaction(true)

    const [activePlayer, setActivePlayer] = useState(null);

    const updateTransactionState = (playerId, txn) => {
        const playerIndex = players.findIndex(p => p.id === playerId)
        players[playerIndex].transactions.push(txn)
        updatePlayers(players)
        console.log({txn, playerId, game})
        switch (txn.type) {
            case "CASH_OUT":
                console.log("cash out");
                game.totalCashOuts += txn.amount
                game.totalNet -= txn.amount
                break;
            case "BUY_IN":
                console.log("buy in");
                game.totalBuyIns += txn.amount
                game.totalNet += txn.amount
                break;
            default:
                console.log("invalid transaction type", {...txn})
        }
    }

    const handlePlayerChange = async (e) => {
        const check = e.target;
        const memberId = check.value;
        const chosen = check.checked;

        if (chosen) {
            try {
                const player = await addPlayer({ gameId: game.id, memberId })
                addPlayerState(player)
            } catch (e) {
                console.error("handlePlayerChange.addPlayer", e)
            }
        } else {
            try {
                await removePlayer({gameId: game.id, playerId: memberId})
                removePlayerState(memberId)
            } catch (e) {
                console.error("handlePlayerChange.removePlayer", e.message)
            }
        }
    };

    const handlePlayerDelete = async (playerId) => {
        try {
            await removePlayer({ gameId: game.id, playerId })
            removePlayerState(playerId)
        } catch (e) {
            console.log("handlePlayerDelete", e.message)
        }
    }

    // Typeahead
    const [typeaheadInput, setTypeaheadInput] = useState("")
    const [typeaheadResults, setTypeaheadResults] = useState([])
    const [showTypeAheadResults, setShowTypeAheadResutls] = useState(false)
    const onTypeaheadChange = (e) => {
        const nameValue = e.target.value
        setShowTypeAheadResutls(true)
        setTypeaheadInput(nameValue)
        setTypeaheadResults(players.filter(p => p.name.toLowerCase().indexOf(typeaheadInput.toLowerCase()) > -1))
    };
    const onResultClick = (p) => (() => {
        setTypeaheadResults([p])
        setTypeaheadInput(p.name)
        setShowTypeAheadResutls(false)
    });
    const onResultClearClick = () => {
        setTypeaheadResults([])
        setTypeaheadInput("")
    }
    const viewablePlayers = typeaheadResults.length > 0 ? typeaheadResults : players

    const playerStats = viewablePlayers.map(p =>
        <PlayerStats
            key={p.id}
            gameId={game.id}
            player={p}
            handleDelete={handlePlayerDelete}
            handleShowAddTransaction={() => {
                setActivePlayer(p)
                handleShowAddTransaction()
            }} />
    );
    return (
        <>
            <GameHeader game={game} onClick={handleShowAddPlayer}/>
            <Col sm={10} className={'mx-auto'}>
                <GameTotals game={game}/>
                <PlayerSearch onChange={onTypeaheadChange} value={typeaheadInput} onClick={onResultClearClick}
                              showTypeAheadResults={showTypeAheadResults} typeaheadResults={typeaheadResults}
                              callbackfn={r => (
                                  <ListGroup.Item
                                      key={r.id}
                                      onClick={onResultClick(r)}
                                  >
                                      {r.name}
                                  </ListGroup.Item>
                              )}/>
                <div className="player-stats">
                    {playerStats}
                </div>
            </Col>
            <AddPlayerModal show={showAddPlayer} handleClose={handleCloseAddPlayer} handleChange={handlePlayerChange}
                            addMemberState={addMember} members={members} players={players}/>
            <AddTransactionModal gameId={game.id} player={activePlayer} show={showAddTransaction}
                                 handleClose={handleCloseAddTransaction}
                                 updateTransactionState={updateTransactionState}/>
        </>
    )
}

export async function loader({ params }) {
    let game = null
    try {
        game = await fetchGame({gameId: params.gameId})
    } catch (e) {
        console.error('Error fetching house data:', e);
        return redirect("/login")
    }

    if (!game) {
        return redirect("/games");
    }

    return { game }
}

