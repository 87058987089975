import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatMoney} from "../../../utilities";
import {ButtonGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Cash, PersonX} from "react-bootstrap-icons";
import {TransactionList} from "./TransactionList";

export function PlayerStats({gameId, player, handleDelete, handleShowAddTransaction}) {
    const [showTransactionList, setShowTransactionList] = useState(false)
    const playerHasTransactions = player.transactions.length > 0;
    const totals = player.transactions.reduce((acc, t) => ["BUY_IN", "ADD_ON"].includes(t.type)
            ? {...acc, buyIn: acc.buyIn + t.amount}
            : {...acc, cashOut: acc.cashOut + t.amount},
        {buyIn: 0, cashOut: 0}
    );

    const net = totals.cashOut - totals.buyIn
    const netColor = net >= 0 ? "text-success" : "text-danger"
    return (
        <Row className="player-stat mb-3">
            <div className="summary" onClick={() => setShowTransactionList(!showTransactionList)}>
                <Row className="summary-content d-flex align-items-center">
                    <Col xs={3} md={4} className="align-self-center"><span
                        className="fw-bolder">{player.name}</span></Col>
                    <Col xs={3} md={2} className="money">{formatMoney(totals.buyIn)}</Col>
                    <Col xs={3} md={2} className="money">{formatMoney(totals.cashOut)}</Col>
                    <Col xs={3} md={2} className={`fw-bold ${netColor} money`}>{formatMoney(net)}</Col>
                    <Col md={2} className="d-flex justify-content-end">
                        <ButtonGroup className="w-100">
                            <Button title="Add Transaction" aria-label="Add Transaction" variant="success" onClick={handleShowAddTransaction}><Cash/></Button>
                            <Button title="Remove Player" disabled={playerHasTransactions} variant="danger"
                                    onClick={() => handleDelete(player.id)}><PersonX/></Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </div>
            <TransactionList gameId={gameId} transactions={player.transactions} showTransactions={showTransactionList}/>
        </Row>
    );
}