const baseUrl = '/api'

const dateFormatter = (date) => {
    const joiner = (date, opts) => {
        const format = (opt) => {
            let formatter = new Intl.DateTimeFormat('en', opt)
            return formatter.format(date)
        }
        return opts.map(format).join('-')
    }
    const options = [{year: "numeric"}, {month: "2-digit"}, {day: "2-digit"}]
    return joiner(date, options)
}

export async function getHouse() {
    let body = {}
    const response = await fetch(`${baseUrl}/houses`);
    body = await response.json();
    if (response.status !== 200) {
        console.error('Error fetching house data:', body);
        throw new Error("Unable to fetch house")
    }
   return body
}

export async function createGame({ description, date }) {
    let game = {}

    if (!description || description === '') {
        throw new Error('You must provide a description for your event')
    }

    const parts = date.split('-');
    const myDate = new Date(parts[0], parts[1] - 1, parts[2]);
    console.log(myDate.toDateString());
    if (isNaN(myDate)) {
        throw new Error('The date provided is invalid')
    }

    const formattedDate = dateFormatter(myDate)

    console.log("createGame()", {date, myDate, utcDate: myDate.toUTCString(), formattedDate})
    const response = await fetch(`${baseUrl}/games`, {
        method: "POST",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            description: description,
            date: formattedDate
        })
    })
    if (response.status !== 201) {
        throw new Error("game could not be created")
    }
    game = await response.json();

    return game
}

export async function addMember({ name }) {
    if (!name || name === '') {
        throw new Error('You must provide a name')
    }

    let member = {}
    const response = await fetch(`${baseUrl}/members`, {
        method: "POST",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: name,
        })
    })
    if (response.status !== 201) {
        if (response.status === 409) {
            throw new Error(`Member ${name} already exists`)
        }
        throw new Error("Invalid member response")
    }
    member = await response.json();
    return member
}

export async function updateMember(memberId, name) {
    if (!memberId || memberId === '') {
        throw new Error('You must provide a member id')
    }
    if (!name || name === '') {
        throw new Error('You must provide a name')
    }

    const response = await fetch(`${baseUrl}/members/${memberId}`, {
        method: "PATCH",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name: name,
        })
    })
    if (response.status !== 200) {
        throw new Error("Invalid member response")
    }

    return await response.json()
}


export async function fetchGame({ gameId }) {
    let game = {};

    try {
        const response = await fetch(`${baseUrl}/games/${gameId}`)
        game = await response.json();
        if (response.status !== 200) {
            game = null
            console.log("unable to load game: ", game)
        }
    } catch (e) {
        console.error('Error fetching house data:', e);
        throw e;
    }
    return game
}

export async function addPlayer({ gameId, memberId }) {
    const response = await fetch(`${baseUrl}/games/${gameId}/players`, {
        method: "POST",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            playerId: memberId
        })
    });

    const body = await response.json()

    if (response.status !== 201) {
        const message = body.error ? body.error : "add player failed"
        throw new Error(message)
    }

    return body
}

export async function removePlayer({ gameId, playerId }) {

    const response = await fetch(`${baseUrl}/games/${gameId}/players/${playerId}`, {
        method: "DELETE",
        cache: "no-cache",
    });

    if (response.status !== 200) {
        const body = await response.json()
        const message = body.error ? body.error : "remove player failed"
        throw new Error(message)
    }
}

export async function createTransaction({gameId, playerId, transaction}) {
    const response = await fetch(`${baseUrl}/games/${gameId}/players/${playerId}/transactions`, {
        method: "POST",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(transaction)
    });

    const body = await response.json()

    if (response.status !== 201) {
        const message = body.error ? body.error : "create transaction failed"
        throw new Error(message)
    }

    return body

}

export async function updateTransaction({gameId, transaction}) {
    const response = await fetch(`${baseUrl}/games/${gameId}/transactions/${transaction.id}`, {
        method: "PUT",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(transaction)
    });

    const body = await response.json()

    if (response.status !== 200) {
        const message = body.error ? body.error : "update transaction failed"
        throw new Error(message)
    }

    return body
}
