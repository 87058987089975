import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from "react";
import {Outlet, useLoaderData} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LinkContainer from "react-router-bootstrap/LinkContainer";

function RootLayout() {
    const {isAuthenticated} = useLoaderData();
    return (
        <Container fluid className="App">
            <Navigation isLoggedIn={isAuthenticated} />
            <Outlet />
        </Container>
    );
}

const Navigation = ({ isLoggedIn }) => {
    return (
        <Navbar fixed="top" expand="md" variant="dark" bg="dark" className="mb-3" collapseOnSelect={true}>
            <Container fluid>
                <LinkContainer to={"/"}>
                    <Navbar.Brand >Poker Ledger</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-md`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                            Menu
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                        {isLoggedIn ? (
                                <>
                                    <LinkContainer to="/games">
                                        <Nav.Link>Games</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to={"/members"}>
                                        <Nav.Link>Members</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to={"/logout"}>
                                        <Nav.Link>Log Out</Nav.Link>
                                    </LinkContainer>
                                </>
                            ) : (
                                <>
                                    <LinkContainer to="/register">
                                        <Nav.Link>Register</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to={"/login"}>
                                        <Nav.Link>Log In</Nav.Link>
                                    </LinkContainer>
                                </>
                            )
                        }
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default RootLayout;