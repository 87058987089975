import {
    createBrowserRouter,
    redirect,
    RouterProvider,
    Form,
    useLocation,
    useActionData
} from "react-router-dom";
import RootLayout from "./RootLayout";
import ErrorPage from "./error-page";
import {Ledger, loader as gameLoader} from "./components/ledger";
import React from "react";

import { authProvider } from "./AuthProvider"
import Landing from "./components/landing/Landing";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button";
import {House} from "./components/house";
import Members from "./components/member/Members";
import {getHouse} from "./services/ledgerService";
import {action as membersAction} from "./components/member/AddMemberForm";
import {action as memberUpdateAction} from "./components/member/EditMemberForm";

const router = createBrowserRouter([
    {
        id: "root",
        path: "/",
        Component: RootLayout,
        ErrorBoundary: ErrorPage,
        loader: async () => {
            await authProvider.load();
            let house = {};
            try {
                house = await getHouse();
            } catch (e) {
                console.error('Error fetching house data:', e);
            }

            return { isAuthenticated: authProvider.isAuthenticated, house }
        },
        children: [
            {
                index: true,
                element: <Landing/>,
            },
            {
                path: "register",
                action: registerAction,
                loader: async () => {
                    if (authProvider.isAuthenticated) {
                        return redirect("/")
                    }
                    return null
                },
                Component: RegisterPage,
            },
            {
                path: "login",
                action: loginAction,
                loader: async () => {
                    if (authProvider.isAuthenticated) {
                        return redirect("/")
                    }
                    return null
                },
                Component: LoginPage,
            },
            {
                id: "house",
                path: "games",
                Component: House,
                loader: protectedRouteLoader(() => null)
            },
            {
                path: "members",
                Component: Members,
                action: membersAction,
                loader: protectedRouteLoader(() => null),
                children: [
                    {
                        path: ':memberId',
                        action: memberUpdateAction,
                    }
                ]
            },
            {
                path: "games/:gameId",
                Component: Ledger,
                loader: protectedRouteLoader(gameLoader),
            },
        ]
    },
    {
        path: "/logout",
        loader: async () => {
            await authProvider.logout();
            return redirect("/")
        },
        ErrorBoundary: ErrorPage,
    }
]);

function protectedRouteLoader(routeLoader) {
    return ({ request, params }) => {
        if (!authProvider.isAuthenticated) {
            let params = new URLSearchParams();
            params.set("from", new URL(request.url).pathname)
            return redirect("/login?" + params.toString())
        }
        return routeLoader({ request, params })
    }
}

async function loginAction({ request }) {
    const { email, password, redirectTo } = Object.fromEntries(await request.formData())

    if (!email || !password) {
        return {
            error: "You must provide username and password to log in"
        }
    }

    try {
        await authProvider.login(email, password)
    } catch (err) {
        console.log("login failed...", err);
        return {
            error: "Invalid login attempt",
        }
    }

    return redirect(redirectTo || "/games");
}

function LoginPage() {
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let from = params.get("from") || "/";

    let actionData = useActionData();

    return (
        <Row className="login">
            <Col xs={10} sm={9} md={8} lg={4} className="mx-auto mt-5 p-5 shadow-sm">
                <Form method="post" replace >
                    <FormControl hidden={true} name="redirectTo" value={from} />
                    <Stack direction="vertical" gap={3}>
                        <FormControl required={true} name="email" type="email" placeholder="email" />
                        <FormControl required={true} name="password" type="password" placeholder="password" />
                        <Button type="submit"  variant="outline-success">Sign In</Button>
                    </Stack>
                </Form>
                {actionData && actionData.error
                    ? <p className="alert-danger">{actionData.error}</p>
                    : <></>}
            </Col>
        </Row>
    );
}

async function registerAction({ request }) {
    const { email, password, firstName, lastName, description, redirectTo } = Object.fromEntries(await request.formData())

    if (!email || !password || !description) {
        return {
            error: "You must provide username and password and description to register"
        }
    }

    try {
        await authProvider.register(email, password, firstName, lastName, description)
    } catch (err) {
        console.log("register failed...", err);
        return {
            error: err.message,
        }
    }

    return redirect(redirectTo || "/games");
}

function RegisterPage() {
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let from = params.get("from") || "/";

    let actionData = useActionData();

    return (
        <Row className="login">
            <Col xs={12} sm={6} className="mx-auto mt-5 p-5 shadow-sm">
                <Form method="post" replace >
                    <FormControl hidden={true} name="redirectTo" value={from} />
                    <Row className="pb-3">
                        <Col xs={6}>
                            <FormControl required={true} name="firstName" type="text" placeholder="first name" />
                        </Col>
                        <Col xs={6}>
                            <FormControl required={true} name="lastName" type="text" placeholder="last name" />
                        </Col>
                    </Row>
                    <Row>
                        <Stack direction="vertical" gap={3}>
                            <FormControl required={true} name="email" type="email" placeholder="email" />
                            <FormControl required={true} name="password" type="password" placeholder="password" />
                            <FormControl required={true} name="description" placeholder="provide your club title" />
                            <Button type="submit"  variant="outline-success">Register</Button>
                        </Stack>
                    </Row>
                    {actionData && actionData.error
                        ? <Row className="pt-3"><Col xs={12}><p className="text-danger">{actionData.error}</p></Col></Row>
                        : <></>
                    }
                </Form>
            </Col>
        </Row>
    );
}

export default function App() {
    return (
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
    )
}