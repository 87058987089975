import React, {useState} from "react";
import {CashCoin, CurrencyDollar, Paypal} from "react-bootstrap-icons";
import {ButtonGroup, FormControl, InputGroup, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {createTransaction} from "../../services/ledgerService";

export const AddTransactionModal = ({gameId, player, show, handleClose, updateTransactionState}) => {
    const [selectedPaymentType, setStatePaymentType] = useState("cash")
    const [isSettled, setStateIsSettled] = useState(true)
    const [transactionType, setStateTransactionType] = useState("buy-in")

    const paymentTypes = [
        {type: "cash", content: <CashCoin/>},
        {type: "venmo", content: "Venmo"},
        {type: "paypal", content: <Paypal/>},
        {type: "cash-app", content: "Cash App"}
    ];

    const handlePaymentTypeClick = (e) => {
        e.preventDefault()
        const paymentType = e.target.value
        if (!paymentType) {
            return;
        }
        setStatePaymentType(paymentType)
        setStateIsSettled(paymentType === "cash")
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const target = e.target
        const data = Array.from(target.elements)
            .filter((i) => i.name)
            .reduce((acc, i) => Object.assign(acc, {[i.name]: i.value}), {})
        data.paymentType = selectedPaymentType
        data.isSettled = isSettled
        data.type = transactionType
        data.amount = parseFloat(data.amount)

        console.log("AddTransactionModal.handleSubmit", JSON.stringify({data}))

        const txn = await createTransaction({ gameId, playerId: player.id, transaction: data})
        console.log("add transaction completed: ", txn)

        updateTransactionState(player.id, txn)
        setStatePaymentType(paymentTypes[0].type)
        setStateIsSettled(true)
        setStateTransactionType("buy-in")
        handleClose()
    };

    if (!player) {
        return null;
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header className="justify-content-between">
                    <Col xs={7}>Add a transaction for <span className="fw-bolder">{player.name}</span></Col>
                    <Col xs={"auto"}>
                        <ButtonGroup>
                            <Button
                                variant={"outline-success"}
                                active={transactionType === "buy-in"}
                                value={"buy-in"}
                                onClick={() => setStateTransactionType("buy-in")}
                            >Buy In</Button>
                            <Button
                                variant={"outline-success"}
                                active={transactionType === "cash-out"}
                                value={"cash-out"}
                                onClick={() => setStateTransactionType("cash-out")}
                            >Cash Out</Button>
                        </ButtonGroup>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-between mb-3">
                        <ButtonGroup>
                            {paymentTypes.map(
                                (t, i) =>
                                    <Button key={i} active={t.type === selectedPaymentType} value={t.type}
                                            onClick={handlePaymentTypeClick}>{t.content}</Button>
                            )}
                        </ButtonGroup>
                    </Row>
                    <Row className="justify-content-center mb-3">
                        <Col xs={7}>
                            <InputGroup>
                                <InputGroup.Text><CurrencyDollar/></InputGroup.Text>
                                <FormControl
                                    type="text"
                                    inputMode="numeric"
                                    name="amount"
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Form.Check
                                type="switch"
                                name="isSettled"
                                label="Settled"
                                checked={isSettled}
                                onChange={() => setStateIsSettled(!isSettled)}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-between">
                        <Col xs="auto">
                            <Button variant="danger" onClick={handleClose}>Cancel</Button>
                        </Col>
                        <Col xs="auto">
                            <Button variant="success" type="submit">Submit</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
    )
}