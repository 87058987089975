import {TransactionDetail} from "./TransactionDetail";
import React from "react";

const transactionValue = (t) => {
    switch (t.type) {
        case 'BUY_IN':
        case 'ADD_ON':
            return -t.amount * 100;
        case 'CASH_OUT':
            return t.amount * 100;
        default:
            return 0;
    }
}

export function TransactionList({gameId, transactions, showTransactions}) {
    if (!showTransactions) {
        return null
    }

    let runningNetCents = 0;

    let details = transactions.map(t => {
        runningNetCents += transactionValue(t)
        return <TransactionDetail key={t.id} gameId={gameId} transaction={t} netAmount={runningNetCents}/>
    })
    return (
        <div className="transactions">
            {details}
        </div>
    );
}