import React, {useState} from "react";
import {Form, redirect, useActionData, useNavigation} from "react-router-dom";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import {updateMember} from "../../services/ledgerService";
import {InputGroup} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const EditMemberForm = ({ member, action, onSubmit }) => {
    const [memberName, setMemberName] = useState(member.name)
    const navigation = useNavigation();
    const errors = useActionData();
    const isSubmitPending = navigation.state !== "idle"
    return (
        <Form method="patch" action={`${member.id}`} onSubmit={onSubmit}>
            <InputGroup>
                <FormControl name='id' hidden={true} value={member.id} readOnly={true} />
                <Col xs={2} sm={4}><Image src={`https://i.pravatar.cc/50?u=${member.id}`} roundedCircle></Image></Col>
                <Col xs={6} sm={4}><FormControl
                    type="text"
                    name='name'
                    required={true}
                    value={memberName}
                    onChange={(e) => setMemberName(e.target.value)}
                /></Col>
                <Col xs="auto" className="md-auto">
                    <Button
                        type='submit'
                        disabled={isSubmitPending}
                    >{isSubmitPending ? "Saving..." : "Submit"}</Button>
                </Col>
            </InputGroup>
            {errors ? <div className="text-danger ms-1">{errors.message}</div> : null}
        </Form>
    )
};

export default EditMemberForm;

export const action = async ({request}) => {
    const {...payload} = Object.fromEntries(await request.formData())
    try {
        await updateMember(payload.id, payload.name)
    } catch (e) {
        return {message: e.message}
    }
    return redirect("/members")
}